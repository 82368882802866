/* eslint-disable no-unused-vars */
/* eslint-disable no-useless-escape */
import React, { useContext, useEffect, useState } from "react";
import Form, { useForm } from "components/molecules/Form";
import Field from "components/molecules/Field";
import Toast from "components/molecules/Toast";
import Button from "components/atoms/Button";
import { subYears, format } from "date-fns";
// eslint-disable-next-line no-unused-vars
import { css } from "styled-components/macro";
import { AuthContext } from "context/authContext";
import { getDateObject } from "helpers/common";
import adminService from "services/adminService";
import Grid from "../../atoms/Grid";
import Select from "components/atoms/Select";
import Upload from "components/molecules/Upload";
import { Seperator } from "./CreateBusinessForm.styles";
import { is } from "date-fns/locale";

const UserForm = ({ onClose, user }) => {
  const [state, setState] = useState({});
  const [startDate, setStartDate] = useState(
    subYears(getDateObject(new Date()), 18)
  );
  const [issueDate, setIssueDate] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [courseStartDate, setCourseStartDate] = useState("");
  const [courseEndDate, setCourseEndDate] = useState("");
  const [instLicExpiry, setInstLicExpiry] = useState("");
  const [inCarInstLicExpiry, setInCarInstLicExpiry] = useState("");
  const [session1Date, setSession1Date] = useState("");
  const [session2Date, setSession2Date] = useState("");
  const [session3Date, setSession3Date] = useState("");
  const [session4Date, setSession4Date] = useState("");
  const [session5Date, setSession5Date] = useState("");
  const [session6Date, setSession6Date] = useState("");

  const [instdructorOptions, setInstructorOptions] = useState({
    carInstructors: [],
    classInstructors: [],
  });

  const [loading, setLoading] = useState(false);
  const [form] = useForm();
  const { refetch } = useContext(AuthContext);

  const licenceList = [
    { label: "G", value: "G" },
    { label: "G1", value: "G1" },
    { label: "G2", value: "G2" },
  ];
  const genderList = [
    { label: "Male", value: "Male" },
    { label: "Female", value: "Female" },
  ];
  const courseList = [
    { label: "Individual Lessons", value: "Individual Lessons" },
    { label: "Others", value: "Others" },
    { label: "Full Course", value: "Full Course" },
    {
      label: "Full Course With Car Road Test",
      value: "Full Course With Car Road Test",
    },
  ];

  useEffect(() => {
    const id = state?.in_car_instructor_select?.value;
    if (id) {
      adminService
        .getSingleInstructor(id)
        .then((response) => {
          form.setFieldsValue({
            in_car_instructor: response?.singleInstructor?.instructor_name,
            in_car_driving_lisence: response?.singleInstructor?.driving_lisence,
            in_car_inst_lic_expiry: new Date(
              response?.singleInstructor?.expiry_date
            )
              ?.toISOString()
              ?.split("T")[0],
          });
        })
        .catch((err) => {
          console.log({ err });
        });
    }
  }, [state?.in_car_instructor_select]);

  useEffect(() => {
    const id = state?.in_class_instructor_select?.value;
    if (id) {
      adminService
        .getSingleInstructor(id)
        .then((response) => {
          form.setFieldsValue({
            in_class_instructor: response?.singleInstructor?.instructor_name,
            in_class_driving_lisence:
              response?.singleInstructor?.driving_lisence,
            inst_lic_expiry: new Date(response?.singleInstructor?.expiry_date)
              ?.toISOString()
              ?.split("T")[0],
          });
        })
        .catch((err) => {
          console.log({ err });
        });
    }
  }, [state?.in_class_instructor_select]);

  useEffect(() => {
    adminService
      .getInstructorsList()
      .then((response) => {
        setInstructorOptions({
          carInstructors: response?.in_car_instructors?.map((e) => ({
            label: e?.instructor_name,
            value: e?._id,
          })),
          classInstructors: response?.in_class_instructors?.map((e) => ({
            label: e?.instructor_name,
            value: e?._id,
          })),
        });
      })
      .catch((err) => {
        console.log({ err });
      });
  }, []);

  useEffect(() => {
    if (user) {
      form.setFieldsValue({
        full_name: user.full_name,
        email: user.email,
        student_number: user.student_number,
        address: user.address,
        cell_phone: user.cell_phone,
        city: user.city,
        in_car_instructor_select: {
          label: user?.in_car_instructor,
          value: user?.in_car_driving_lisence,
        },
        in_class_instructor_select: {
          label: user?.in_class_instructor,
          value: user?.in_car_driving_lisence,
        },
        postal_code: user.postal_code,
        home_phone: user.home_phone,
        dob: format(getDateObject(user?.dob), "yyyy-MM-dd"),
        // dob: format(new Date(user?.dob), 'yyyy-MM-dd'),
        issue_date: user?.issue_date
          ? format(new Date(user?.issue_date), "yyyy-MM-dd")
          : "",
        expiry_date: user?.expiry_date
          ? format(new Date(user.expiry_date), "yyyy-MM-dd")
          : "",
        course_start_date: user?.course_start_date
          ? format(new Date(user.course_start_date), "yyyy-MM-dd")
          : "",
        course_end_date: user?.courseEndDate
          ? format(new Date(user.courseEndDate), "yyyy-MM-dd")
          : "",
        inst_lic_expiry: user?.inst_lic_expiry
          ? format(new Date(user.inst_lic_expiry), "yyyy-MM-dd")
          : "",
        in_car_inst_lic_expiry: user?.in_car_inst_lic_expiry
          ? format(new Date(user.in_car_inst_lic_expiry), "yyyy-MM-dd")
          : "",
        session_start_time: user?.session_start_time || "",
        session_end_time: user?.session_end_time || "",
        session_1_date: user?.session_1_date
          ? format(new Date(user.session_1_date), "yyyy-MM-dd")
          : "",
        session_2_date: user?.session_2_date
          ? format(new Date(user.session_2_date), "yyyy-MM-dd")
          : "",
        session_3_date: user?.session_3_date
          ? format(new Date(user.session_3_date), "yyyy-MM-dd")
          : "",
        session_4_date: user?.session_4_date
          ? format(new Date(user.session_4_date), "yyyy-MM-dd")
          : "",
        session_5_date: user?.session_5_date
          ? format(new Date(user.session_5_date), "yyyy-MM-dd")
          : "",
        session_6_date: user?.session_6_date
          ? format(new Date(user.session_6_date), "yyyy-MM-dd")
          : "",
        course_number: user.course_number ? user.course_number : "",
        license_image: user.license_image !== null ? user.license_image : "",
        driving_lisence: user?.driving_lisence,
        in_class_driving_lisence: user?.in_class_driving_lisence
          ? user?.in_class_driving_lisence
          : "",
        in_car_driving_lisence: user?.in_car_driving_lisence
          ? user?.in_car_driving_lisence
          : "",
        emergency_contact: user?.emergency_contact,
        in_class_instructor: user?.in_class_instructor
          ? user?.in_class_instructor
          : "",
        in_car_instructor: user?.in_car_instructor
          ? user?.in_car_instructor
          : "",
        gender: { label: user.gender, value: user.gender },
        course: { label: user?.course, value: user?.course },
        licence_type: { label: user.licence_type, value: user.licence_type },
      });
      setStartDate(new Date(user.dob));
      if (user.issue_date !== "") {
        setIssueDate(new Date(user.issue_date));
      }
      if (user.expiry_date !== "") {
        setExpiryDate(new Date(user.expiry_date));
      }
      if (user.inst_lic_expiry !== "") {
        setInstLicExpiry(new Date(user.inst_lic_expiry));
      }
      if (user.in_car_inst_lic_expiry) {
        setInCarInstLicExpiry(new Date(user.in_car_inst_lic_expiry));
      }
      if (user.session_1_date) {
        setSession1Date(new Date(user.session_1_date));
      }
      if (user.session_2_date) {
        setSession2Date(new Date(user.session_2_date));
      }
      if (user.session_3_date) {
        setSession3Date(new Date(user.session_3_date));
      }
      if (user.session_4_date) {
        setSession4Date(new Date(user.session_4_date));
      }
      if (user.session_5_date) {
        setSession5Date(new Date(user.session_5_date));
      }
      if (user.session_6_date) {
        setSession6Date(new Date(user.session_6_date));
      }
      if (user?.course_start_date) {
        setCourseStartDate(new Date(user.course_start_date));
      }
      if (user?.courseEndDate) {
        setCourseEndDate(new Date(user.courseEndDate));
      }
    }
  }, []);

  const onDownloadPdf = async (id, user, type) => {
    try {
      const res = await adminService.downloadPdf({ id, type });
      const a = document.createElement("a");
      a.href = URL.createObjectURL(new Blob([res]));
      a.download = `${user.full_name}.pdf`;
      a.click();
      setLoading(false);
      document.body.appendChild(a);
      document.body.removeChild(a);
      refetch();
      setLoading(false);
      onClose();
      Toast({
        message: "Student Created Successfully and PDF form Downloaded",
        type: "success",
      });
    } catch (ex) {
      refetch();
      setLoading(false);
      onClose();
      Toast({
        type: "error",
        message:
          "Student Created but failed to download pdf kindly download it manually",
      });
    }
  };
  const handleSubmit = async (e) => {
    const payload = {
      ...e,
      dob: startDate ?? null,
      issue_date: issueDate ?? null,
      expiry_date: expiryDate ?? null,
      course_start_date: courseStartDate ?? null,
      courseEndDate: courseEndDate ?? null,
      inst_lic_expiry: e?.inst_lic_expiry,
      in_car_inst_lic_expiry: e?.in_car_inst_lic_expiry,
      session_start_time: e?.session_start_time,
      session_end_time: e?.session_end_time,
      session_1_date: session1Date ?? null,
      session_2_date: session2Date ?? null,
      session_3_date: session3Date ?? null,
      session_4_date: session4Date ?? null,
      session_5_date: session5Date ?? null,
      session_6_date: session6Date ?? null,
    };
    setLoading(true);
    if (user) {
      adminService
        .editUser(user._id, payload)
        .then((res) => {
          if (res.error) {
            setLoading(false);
            Toast({
              type: "error",
              message: res?.message,
            });
          } else {
            setLoading(false);
            refetch();
            onClose();
            Toast({
              type: "success",
              message: res?.message,
            });
          }
        })
        .catch((err) => {
          setLoading(false);
          Toast({
            type: "error",
            message: err?.message,
          });
        });
    } else {
      adminService
        .createUser(payload)
        .then((res) => {
          if (res.error) {
            setLoading(false);
            Toast({
              type: "error",
              message: res?.message,
            });
          } else {
            onDownloadPdf(res?.newUser?._id, res?.newUser, "user");
          }
        })
        .catch((err) => {
          setLoading(false);
          Toast({
            type: "error",
            message: err?.message,
          });
        });
    }
  };

  return (
    <Form
      form={form}
      onSubmit={handleSubmit}
      onTouched={(e) => {
        setState((_) => ({ ..._, ...e }));
      }}
      onError={() => {
        Toast({
          type: "error",
          message: "Please fill all the required fields",
        });
      }}
    >
      <Seperator>Student Details</Seperator>
      <Grid xs={1} lg={2} colGap={20}>
        <Form.Item
          type="text"
          label="Full Name"
          name="full_name"
          placeholder="Full Name"
          rules={[
            { required: true, message: "Full Name is required" },
            { changeRegex: "capitalize" },
            {
              pattern: /^[a-zA-Z][a-zA-Z0-9\s`',.,-]*$/,

              message: "Enter a valid name",
            },
            {
              pattern: /^[a-zA-Z0-9\s`!@#$%^&*()_+-=:";'<>,.,-]{3,}$/,
              message: "Name must be minimum 3 characters",
            },
            {
              pattern: /^[a-zA-Z0-9\s`!@#$%^&*()_+-=:";'<>,.,-]{3,40}$/,
              message: "Name must be maximum 40 characters",
            },
          ]}
        >
          <Field />
        </Form.Item>
        <Form.Item type="text" label="Email" name="email" placeholder="Email">
          <Field />
        </Form.Item>
      </Grid>
      <Form.Item
        type="text"
        label="Address"
        name="address"
        placeholder="Address"
        rules={[
          { required: true, message: "Address is required" },
          { changeRegex: "capitalize" },
        ]}
      >
        <Field />
      </Form.Item>
      <Grid xs={1} lg={2} colGap={20}>
        <Form.Item
          type="text"
          label="City"
          name="city"
          placeholder="City"
          rules={[
            { required: true, message: "City is required" },
            { changeRegex: "capitalize" },
          ]}
        >
          <Field />
        </Form.Item>
        <Form.Item
          type="text"
          label="Postal Code"
          name="postal_code"
          placeholder="Postal Code"
          rules={[
            { required: true, message: "Postal Code is required" },
            { changeRegex: "postal_code" },
          ]}
        >
          <Field />
        </Form.Item>
      </Grid>
      <Grid xs={1} lg={3} colGap={20}>
        <Form.Item
          type="text"
          label="Home Phone"
          name="home_phone"
          placeholder="000-000-0000"
          rules={[
            { required: true, message: "Home Phone is required" },
            { changeRegex: "phone_number" },
          ]}
        >
          <Field />
        </Form.Item>
        <Form.Item
          type="text"
          label="Cellular #"
          name="cell_phone"
          placeholder="000-000-0000"
          rules={[
            { required: true, message: "Cellular # is required" },
            { changeRegex: "phone_number" },
          ]}
        >
          <Field />
        </Form.Item>

        <Form.Item
          type="text"
          label="Emergency Contact #"
          name="emergency_contact"
          placeholder="000-000-0000"
          rules={[{ changeRegex: "phone_number" }]}
        >
          <Field />
        </Form.Item>
      </Grid>
      <Grid xs={1} lg={2} colGap={20}>
        <Form.Item
          type="text"
          label="Driving License #"
          name="driving_lisence"
          placeholder="A0000-00000-00000"
          rules={[
            { required: true, message: "Driving License # is required" },
            {
              pattern: /^[A-Z][0-9]{4}-[0-9]{5}-[0-9]{5}$/,
              message: "Invalid Licence Number",
            },
            { changeRegex: "licence" },
          ]}
        >
          <Field />
        </Form.Item>
        <Form.Item
          id="offer"
          type="select"
          label="Driving License Type"
          name="licence_type"
          options={licenceList}
          placeholder="Driving License Type"
          rules={[{ required: true, message: "Please Select Licence Type" }]}
        >
          <Select />
        </Form.Item>
      </Grid>
      <Grid xs={1} lg={3} colGap={20}>
        <Form.Item
          id="offer"
          type="select"
          label="Gender"
          name="gender"
          // value={form.getFieldValue('gender')?.value}
          options={genderList}
          placeholder="Gender"
          // onChange={e => {
          //   form.setFieldsValue({ gender: e.target.value.value });
          // }}
          rules={[{ required: true, message: "Please Select Gender" }]}
        >
          <Select />
        </Form.Item>
        <Form.Item
          id="course"
          type="select"
          label="Course"
          name="course"
          // value={form.getFieldValue('gender')?.value}
          options={courseList}
          placeholder="Course"
          // onChange={e => {
          //   form.setFieldsValue({ course: e.target.value.value });
          // }}
          rules={[{ required: true, message: "Please Select Course" }]}
        >
          <Select />
        </Form.Item>
        <Form.Item
          type="text"
          label="Course #"
          name="course_number"
          placeholder="Course #"
        >
          <Field />
        </Form.Item>
      </Grid>
      <Grid xs={1} lg={3} colGap={20}>
        <Form.Item
          sm
          name="dob"
          label="Date of Birth"
          selected={startDate ? new Date(startDate) : new Date()}
          onChange={(date) => {
            form.setFieldsValue({
              dob: date?.target?.value ? date.target.value : "",
            });
            if (date?.target?.value) {
              setStartDate(date.target.value);
            }
          }}
          isClearable
          prefix={<i className="material-icons-outlined">date_range</i>}
          placeholderText="Select Date of Birth"
          // excludeDateIntervals={[
          //   {
          //     start: subYears(getDateObject(new Date()), 18),
          //     end: addYears(getDateObject(new Date()), 1),
          //   },
          // ]}
          type="datepicker"
          rules={[{ required: true, message: "Please Select Date of Birth" }]}
        >
          <Field />
        </Form.Item>
        <Form.Item
          sm
          name="issue_date"
          label="Issue Date"
          selected={issueDate ? new Date(issueDate) : new Date()}
          onChange={(date) => {
            form.setFieldsValue({
              issue_date: date?.target?.value ? date.target.value : "",
            });
            if (date.target.value === null) {
              setIssueDate("");
            } else {
              setIssueDate(date.target.value);
            }
          }}
          rules={[{ required: true, message: "Please Select Issue Date" }]}
          isClearable
          prefix={<i className="material-icons-outlined">date_range</i>}
          placeholderText="Select Issue Date"
          type="datepicker"
        >
          <Field />
        </Form.Item>
        <Form.Item
          sm
          name="expiry_date"
          label="License Expiry Date"
          selected={expiryDate ? new Date(expiryDate) : new Date()}
          onChange={(date) => {
            form.setFieldsValue({
              expiry_date: date?.target?.value ? date.target.value : "",
            });
            if (date.target.value === null) {
              setExpiryDate("");
            } else {
              setExpiryDate(date.target.value);
            }
          }}
          rules={[
            { required: true, message: "Please Select Licence Expiry Date" },
          ]}
          isClearable
          prefix={<i className="material-icons-outlined">date_range</i>}
          placeholderText="Select Date"
          type="datepicker"
        >
          <Field />
        </Form.Item>
      </Grid>
      <Grid xs={1} lg={3} colGap={20}>
        <Form.Item
          sm
          name="course_start_date"
          label="Course Start Date "
          selected={courseStartDate ? new Date(courseStartDate) : new Date()}
          onChange={(date) => {
            form.setFieldsValue({
              course_start_date: date?.target?.value ? date?.target?.value : "",
            });
            if (date.target.value === null) {
              setCourseStartDate("");
            } else {
              setCourseStartDate(date.target.value);
            }
          }}
          isClearable
          prefix={<i className="material-icons-outlined">date_range</i>}
          placeholderText="Select Date"
          type="datepicker"
        >
          <Field />
        </Form.Item>
        <Form.Item
          sm
          name="course_end_date"
          label="Course End Date"
          selected={courseEndDate ? new Date(courseEndDate) : new Date()}
          onChange={(date) => {
            form.setFieldsValue({
              course_end_date: date?.target?.value ? date?.target?.value : "",
            });
            if (date.target.value === null) {
              setCourseEndDate("");
            } else {
              setCourseEndDate(date.target.value);
            }
          }}
          isClearable
          prefix={<i className="material-icons-outlined">date_range</i>}
          placeholderText="Select Date"
          type="datepicker"
        >
          <Field />
        </Form.Item>
      </Grid>
      <Form.Item
        name="license_image"
        label="License Image"
      >
        <Upload
          allowPreview
          base64
          uploadBtnText="Upload Image"
          onChange={(e) => console.log("called")}
        />
      </Form.Item>
      <Seperator>Instructors Details</Seperator>
      <Form.Item
        name="in_car_instructor_select"
        placeholder="Select Instructor"
        label="Select In Car Instructor"
        rules={[{ required: true, message: "Instructor is required" }]}
      >
        <Select
          sm
          options={instdructorOptions?.carInstructors}
          isSearchable
          hideSelectedOptions={false}
          closeMenuOnSelect={true}
        />
      </Form.Item>
      <Grid xs={1} lg={3} colGap={20}>
        <Form.Item
          type="text"
          label="In Car Instructor"
          name="in_car_instructor"
          disabled
          placeholder="In Car Instructor Name"
        >
          <Field />
        </Form.Item>
        <Form.Item
          type="text"
          label="In Car Driving License #"
          name="in_car_driving_lisence"
          disabled
          placeholder="Driving License #"
        >
          <Field />
        </Form.Item>
        <Form.Item
          name="in_car_inst_lic_expiry"
          label="In Car Instr Licence Expiry"
          disabled
          selected={inCarInstLicExpiry}
          onChange={(date) => {
            form.setFieldsValue({
              in_car_inst_lic_expiry: date?.target?.value
                ? date?.target?.value
                : "",
            });
            if (date.target.value === null) {
              setInCarInstLicExpiry("");
            } else {
              setInCarInstLicExpiry(date.target.value);
            }
          }}
          isClearable
          prefix={<i className="material-icons-outlined">date_range</i>}
          placeholder="Select Expiry Date"
          type="text"
        >
          <Field />
        </Form.Item>
      </Grid>
      <Form.Item
        name="in_class_instructor_select"
        label="In Class Instructor"
        rules={[{ required: true, message: "Instructor is required" }]}
      >
        <Select
          sm
          options={instdructorOptions?.classInstructors}
          isSearchable
          hideSelectedOptions={false}
          closeMenuOnSelect={true}
        />
      </Form.Item>
      <Grid xs={1} lg={3} colGap={20}>
        <Form.Item
          type="text"
          label="In Class Instructor"
          name="in_class_instructor"
          disabled
          placeholder="In Class Instructor Name"
        >
          <Field />
        </Form.Item>
        <Form.Item
          type="text"
          label="In Class Driving License #"
          name="in_class_driving_lisence"
          disabled
          placeholder="Driving License #"
        >
          <Field />
        </Form.Item>
        <Form.Item
          name="inst_lic_expiry"
          label="In Class Instr Licence Expiry"
          disabled
          selected={instLicExpiry ? new Date(instLicExpiry) : new Date()}
          onChange={(date) => {
            form.setFieldsValue({
              inst_lic_expiry: date?.target?.value ? date?.target?.value : "",
            });
            if (date.target.value === null) {
              setInstLicExpiry("");
            } else {
              setInstLicExpiry(getDateObject(date.target.value));
            }
          }}
          isClearable
          prefix={<i className="material-icons-outlined">date_range</i>}
          placeholder="Select Expiry Date"
          type="text"
        >
          <Field />
        </Form.Item>
      </Grid>
      <Seperator>Session Details</Seperator>
      <Grid xs={1} lg={2} colGap={20}>
        <Form.Item
          type="text"
          label="Class Start Time"
          name="session_start_time"
          placeholder="Start Time"
          rules={[
            {
              pattern: /^([01][0-9]|2[0-3]):([0-5][0-9])$/,
              message: "Enter a valid time value i.e 06:00, 00:59, 20:15",
            },
          ]}
        >
          <Field />
        </Form.Item>
        <Form.Item
          type="text"
          label="Class End Time"
          name="session_end_time"
          placeholder="End Time"
          rules={[
            {
              pattern: /^([01][0-9]|2[0-3]):([0-5][0-9])$/,
              message: "Enter a valid time value i.e 06:00, 00:59, 20:15",
            },
          ]}
        >
          <Field />
        </Form.Item>
      </Grid>
      <Grid xs={1} lg={3} colGap={20}>
        <Form.Item
          sm
          name="session_1_date"
          label="Session date 1"
          selected={session1Date ? new Date(session1Date) : new Date()}
          onChange={(date) => {
            form.setFieldsValue({
              dob: date?.target?.value ? date.target.value : "",
            });
            form.setFieldsValue({
              session_1_date: date?.target?.value ? date.target.value : "",
            });
            if (date.target.value === null) {
              setSession1Date("");
            } else {
              setSession1Date(date.target.value);
            }
          }}
          isClearable
          prefix={<i className="material-icons-outlined">date_range</i>}
          placeholderText="Select Date"
          type="datepicker"
        >
          <Field />
        </Form.Item>
        <Form.Item
          sm
          name="session_2_date"
          label="Session date 2"
          selected={session2Date ? new Date(session2Date) : new Date()}
          onChange={(date) => {
            form.setFieldsValue({
              session_2_date: date?.target?.value ? date.target.value : "",
            });
            if (date.target.value === null) {
              setSession2Date("");
            } else {
              setSession2Date(date.target.value);
            }
          }}
          isClearable
          prefix={<i className="material-icons-outlined">date_range</i>}
          placeholderText="Select Date"
          type="datepicker"
        >
          <Field />
        </Form.Item>
        <Form.Item
          sm
          name="session_3_date"
          label="Session date 3"
          selected={session3Date ? new Date(session3Date) : new Date()}
          onChange={(date) => {
            form.setFieldsValue({
              session_3_date: date?.target?.value ? date.target.value : "",
            });
            if (date.target.value === null) {
              setSession3Date("");
            } else {
              setSession3Date(date.target.value);
            }
          }}
          isClearable
          prefix={<i className="material-icons-outlined">date_range</i>}
          placeholderText="Select Date"
          type="datepicker"
        >
          <Field />
        </Form.Item>
      </Grid>
      <Grid xs={1} lg={3} colGap={20}>
        <Form.Item
          sm
          name="session_4_date"
          label="Session date 4"
          selected={session4Date ? new Date(session4Date) : new Date()}
          onChange={(date) => {
            form.setFieldsValue({
              session_4_date: date?.target?.value ? date.target.value : "",
            });
            if (date.target.value === null) {
              setSession4Date("");
            } else {
              setSession4Date(date.target.value);
            }
          }}
          isClearable
          prefix={<i className="material-icons-outlined">date_range</i>}
          placeholderText="Select Date"
          type="datepicker"
        >
          <Field />
        </Form.Item>
        <Form.Item
          sm
          name="session_5_date"
          label="Session date 5"
          selected={session5Date ? new Date(session5Date) : new Date()}
          onChange={(date) => {
            form.setFieldsValue({
              session_5_date: date?.target?.value ? date.target.value : "",
            });
            if (date.target.value === null) {
              setSession5Date("");
            } else {
              setSession5Date(date.target.value);
            }
          }}
          isClearable
          prefix={<i className="material-icons-outlined">date_range</i>}
          placeholderText="Select Date"
          type="datepicker"
        >
          <Field />
        </Form.Item>
        <Form.Item
          sm
          name="session_6_date"
          label="Session date 6"
          selected={session6Date ? new Date(session6Date) : new Date()}
          onChange={(date) => {
            form.setFieldsValue({
              session_6_date: date?.target?.value ? date.target.value : "",
            });
            if (date.target.value === null) {
              setSession6Date("");
            } else {
              setSession6Date(date.target.value);
            }
          }}
          isClearable
          prefix={<i className="material-icons-outlined">date_range</i>}
          placeholderText="Select Date"
          type="datepicker"
        >
          <Field />
        </Form.Item>
      </Grid>
      <Button
        loading={loading}
        type="primary"
        htmlType="submit"
        rounded
        sm
        width={155}
        css={`
          margin: 0 auto 1.25rem;
          @media (min-width: 768px) {
            margin-bottom: 2.125rem;
          }
        `}
      >
        Submit
      </Button>
    </Form>
  );
};

export default UserForm;
